import { Card } from "@/src/components/Card";
import {
  useDealQuery,
  useUpdateDealPropertyValuesMutation,
} from "@/src/graphql/generated";
import useGqlClient from "@/src/hooks/useGqlClient";
import { useSelector } from "react-redux";
import { authSelectors } from "@/src/store/auth/selector";
import { useMemo, type ChangeEvent, useEffect, useState } from "react";
import { classNames } from "@/src/utils/cn";
import { TextInput } from "@/src/components/tailwind/TextInput";
import { Button } from "@/src/components/tailwind/Button";
import { toasts } from "@/src/components/toasts/toasts";

export const DealPropertyValue = () => {
  const client = useGqlClient();
  const activeDealId = useSelector(authSelectors.activeDealId);
  const { data } = useDealQuery(client, { id: activeDealId ?? "" });
  const { mutate } = useUpdateDealPropertyValuesMutation(client);

  const dealProperties = useMemo(() => {
    return [...(data?.deal?.dealPropertyValues ?? [])].sort(
      (a, b) => (a?.createdAt ?? 0) - (b?.createdAt ?? 0)
    );
  }, [data]);

  const [propertyValues, setPropertyValues] = useState<Record<string, string>>(
    {}
  );
  const [loading, setLoading] = useState(false);

  let initialDealProperties = useMemo(() => {
    if (dealProperties.length > 0) {
      return dealProperties.reduce<Record<string, string>>((acc, dp) => {
        acc[dp.id] = dp.propertyValue ?? "";
        return acc;
      }, {});
    }
    return {};
  }, [dealProperties]);

  useEffect(() => {
    if (dealProperties.length > 0) {
      setPropertyValues(initialDealProperties);
    }
  }, [dealProperties, initialDealProperties]);

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>, id: string) => {
    setPropertyValues((prev) => ({
      ...prev,
      [id]: e.target.value,
    }));
  };

  const hasChanged = useMemo(() => {
    return (
      JSON.stringify(initialDealProperties) !== JSON.stringify(propertyValues)
    );
  }, [propertyValues, initialDealProperties]);

  const handleUpdate = () => {
    if (loading) return;
    setLoading(true);

    //separate the changes
    const changes = Object.entries(propertyValues)
      .map(([key, value]) => ({ id: key, propertyValue: value }))
      .filter(
        ({ id, propertyValue }) => initialDealProperties[id] !== propertyValue
      );

    mutate(
      {
        input: changes,
      },
      {
        onSuccess: () => {
          for (const { id, propertyValue } of changes) {
            initialDealProperties[id] = propertyValue;
            setPropertyValues(initialDealProperties);
          }
          setLoading(false);
          toasts.success("Deal properties updated");
        },
        onError: (e) => {
          setLoading(false);
          toasts.error("Failed to update deal properties");
        },
      }
    );
  };

  if (data?.deal.dealPropertyValues.length === 0) {
    return (
      <section>
        <h1>No Deal Properties Yet!</h1>
      </section>
    );
  }

  return (
    <div className="w-full xl:w-1/2">
      <p className="font-semibold text-gray-800">Custom properties</p>
      <Card margin="m 0 0 0" padding="m">
        {dealProperties.map((dp, i) => (
          <div
            className={classNames(
              "flex items-center justify-between border py-3 border-gray-200 p-2",
              i === 0 ? "rounded-t-md" : "",
              i === dealProperties.length - 1 ? "rounded-b-md" : "",
              i > 0 ? "border-t-0" : ""
            )}
            key={dp.id}
          >
            <p className="font-semibold text-gray-700 text-md">
              {dp.propertyKey}
            </p>
            <div className="w-1/2">
              <TextInput
                disabled={loading}
                value={propertyValues[dp.id] ?? ""}
                onChange={(e) => handleTextChange(e, dp.id)}
                placeholder="Enter value..."
              />
            </div>
          </div>
        ))}

        <div className="mt-4 flex justify-end gap-x-2">
          <Button
            variant="neutral"
            text="Discard changes"
            onClick={() => setPropertyValues(initialDealProperties)}
            isDisabled={!hasChanged}
          />

          <Button
            variant="positive"
            isDisabled={!hasChanged}
            onClick={handleUpdate}
            text="Save"
            isLoading={loading}
            loadingText="Saving..."
          />
        </div>
      </Card>
    </div>
  );
};
