import { NavLink, useHistory, useParams } from "react-router-dom";
import useGqlClient from "../../../hooks/useGqlClient";
import { useQueryClient } from "@tanstack/react-query";
import {
  useDdqlQuery,
  Question as GqlQuestion,
  DdqlStatus,
  Ddql as GqlDdql,
  usePublishDdqlMutation,
  useDeleteDdqlMutation,
} from "../../../graphql/generated";
import { classNames } from "../../../utils/cn";
import { Button } from "../../../components/tailwind/Button";
import Loading from "../../../components/Loading";
import { H3, H4 } from "../../../components/Heading";
import { PageContent } from "../../../components/PageContent";
import { Card } from "../../../components/Card";
import { formatDistanceToNow, fromUnixTime } from "date-fns";
import { StatusPill } from "../Group";
import { NewQuestionModal, Question } from "..";
import { NewDDQLModal } from "./../NewDDQLModal";
import { toasts } from "../../../components/toasts/toasts";
import {
  PlusIcon,
  CheckCircleIcon,
  XCircleIcon,
  ChevronRightIcon,
} from "@heroicons/react/24/solid";
import { useState } from "react";

export function Ddql() {
  const { id } = useParams<{ id: string }>();

  const history = useHistory();
  const [openModal, setOpenModal] = useState<
    "" | "import_ddql" | "new_question" | "create_ddql"
  >("");

  const client = useGqlClient();
  const queryClient = useQueryClient();
  const ddqlQuery = useDdqlQuery(client, {
    id,
  });

  const publishDdql = usePublishDdqlMutation(client);
  const deleteDdql = useDeleteDdqlMutation(client);

  if (ddqlQuery.error) {
    return (
      <div className="flex flex-col flex-1 justify-center items-center h-full w-full">
        <p className="font-semibold text-gray-600 text-sm">
          Something went wrong
        </p>
        <p className="text-gray-500/80 text-xs">
          Sorry about that. Try again or contact support.
        </p>
        <Button
          margin="m 0 0 0"
          variant="neutral"
          text="Try again"
          onClick={() => {
            ddqlQuery.refetch();
          }}
        />
      </div>
    );
  }

  if (ddqlQuery.isLoading || !ddqlQuery.data) {
    return <Loading />;
  }

  const questions = ddqlQuery.data.ddql.questions;
  const children = ddqlQuery.data.ddql.children;

  return (
    <div className="flex-1 flex-col flex">
      <div className="bg-white sticky right-0 z-20 top-0 px-8 py-3 w-full border-b border-gray-300/80">
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-x-2">
            <NavLink to="/deal/questions">
              <h4 className="font-bold text-gray-400 hover:text-gray-700">
                Questions
              </h4>
            </NavLink>
            <p className="text-gray-400">/</p>
            <div className="font-semibold">
              <H4>{ddqlQuery.data.ddql.name}</H4>
            </div>
          </div>
          <div></div>
        </div>
      </div>
      <PageContent>
        <div className="flex justify-between items-center lg:w-1/2">
          <div>
            <div className="font-bold text-gray-800">
              <H3>{ddqlQuery.data.ddql.name}</H3>
            </div>
            <p className="text-xs text-gray-500/80">
              Created by {ddqlQuery.data.ddql.createdBy.name}{" "}
              {formatDistanceToNow(
                fromUnixTime(ddqlQuery.data.ddql.createdAt),
                {
                  addSuffix: true,
                }
              )}
            </p>
          </div>

          <div className="flex items-center gap-x-3">
            <StatusPill ddql={ddqlQuery.data.ddql} />


            {ddqlQuery.data.ddql.status === DdqlStatus.Initial && (
              <div className="flex gap-x-2">
                <button
                  onClick={() => setOpenModal("create_ddql")}
                  className="text-indigo-500 text-sm font-semibold flex items-center gap-x-1 hover:text-indigo-600"
                >
                  <PlusIcon className="w-4 h-4" />
                  Add list
                </button>
                <button
                  onClick={() => setOpenModal("new_question")}
                  className="text-indigo-500 text-sm font-semibold flex items-center gap-x-1 hover:text-indigo-600"
                >
                  <PlusIcon className="w-4 h-4" />
                  Add question
                </button>
              </div>
            )}

            {(ddqlQuery.data.ddql.status === DdqlStatus.Imported ||
              ddqlQuery.data.ddql.status === DdqlStatus.ImportFailed || ddqlQuery.data.ddql.status === DdqlStatus.Initial) && (
              <Button
                size="s"
                variant="neutral"
                icon={XCircleIcon}
                text="Delete DDQL"
                onClick={() => {
                  deleteDdql.mutate(
                    {
                      id: id,
                    },
                    {
                      onSuccess: () => {
                        history.push(`/deal/questions`);
                        toasts.success("DDQL deleted");
                      },
                      onError: (e) => {
                        toasts.error("Failed to delete DDQL");
                      },
                    }
                  );
                }}
              />
            )}

            {(ddqlQuery.data.ddql.status === DdqlStatus.Imported || ddqlQuery.data.ddql.status === DdqlStatus.Initial) && (
              <Button
                size="s"
                variant="positive"
                icon={CheckCircleIcon}
                text="Publish DDQL"
                onClick={() => {
                  publishDdql.mutate(
                    {
                      id: id,
                    },
                    {
                      onSuccess: () => {
                        history.push(`/deal/questions`);
                        toasts.success("DDQL published");
                      },
                      onError: (e) => {
                        toasts.error("Failed to publish DDQL");
                      },
                    }
                  );
                }}
              />
            )}


          </div>
        </div>

        <div className="mt-5 lg:w-1/2">
          <Card margin="s 0 0 0">
            {children.length === 0 && questions.length === 0 ? (
              <div className="p-6 flex flex-col justify-center items-center flex-1">
                <p className="text-gray-600 text-sm font-medium">
                  No content yet
                </p>
                {/* <p className="text-xs text-gray-400 mt-1">
                  Add a question or create a new list
                </p> */}
              </div>
            ) : (
              <div className="flex flex-col py-2">
                {/* Show questions for this DDQL */}
                {questions?.length > 0 && (
                  <div className="pl-6">
                    {questions
                      .sort((a, b) => {
                        if (a.answer && !b.answer) return 1;
                        if (!a.answer && b.answer) return -1;
                        return 0;
                      })
                      .map((q, qIndex) => (
                        <Question
                          key={q.id}
                          isFirst={qIndex === 0}
                          isLast={qIndex === questions.length - 1}
                          question={q as GqlQuestion}
                          showAnswered={false}
                          showAvatar={false}
                        />
                      ))}
                  </div>
                )}

                {/* Render child DDQLs */}
                {children?.map((childDdql, index) => (
                  <DDQLNode
                    key={childDdql.id}
                    ddql={childDdql as GqlDdql}
                    depth={0}
                    isLast={index === children.length - 1}
                    rootDdqlStatus={ddqlQuery.data.ddql.status || DdqlStatus.Initial}
                  />
                ))}
              </div>
            )}
          </Card>
        </div>
      </PageContent>
      <NewQuestionModal
        open={openModal === "new_question"}
        onClose={() => setOpenModal("")}
        ddqlId={id}
      />
      <NewDDQLModal
        open={openModal === "create_ddql"}
        onClose={() => {
          setOpenModal("");
          queryClient.invalidateQueries({
            queryKey: ["Ddql"],
          });
        }}
        parentDdqlID={ddqlQuery.data.ddql.id}
        dealFirmGroupId={ddqlQuery.data.ddql.dealGroup?.id ?? ""}
      />
    </div>
  );
}

interface DDQLNodeProps {
  ddql: GqlDdql;
  depth?: number;
  isLast?: boolean;
  rootDdqlStatus: DdqlStatus;
}

function DDQLNode({ ddql, depth = 0, isLast = false, rootDdqlStatus }: DDQLNodeProps) {
  const [isCollapsed, setIsCollapsed] = useState(depth > 0);
  const marginClasses = ["ml-0", "ml-4", "ml-8", "ml-12", "ml-16", "ml-20"];

  return (
    <div className={classNames("flex flex-col", depth === 0 ? "mt-4" : "mt-2")}>
      <NavLink to={`/deal/questions/lists/${ddql.id}`}>
        <div
          className={classNames(
            "flex items-center justify-between rounded-md transition-colors duration-150",
            "hover:bg-indigo-50/70 group",
            depth === 0 ? "px-5 py-3" : "px-4 py-2.5",
            depth > 0 ? marginClasses[depth] : "",
            "border-l-2"
          )}
          onClick={(e) => {
            if (depth > 0) {
              e.preventDefault();
              setIsCollapsed(!isCollapsed);
            }
          }}
        >
          <div className="flex items-center gap-x-2">
            {depth > 0 &&
              (ddql.questions?.length > 0 || ddql.children?.length > 0) && (
                <ChevronRightIcon
                  className={classNames(
                    "w-4 h-4 text-gray-400 transition-transform",
                    isCollapsed ? "" : "rotate-90"
                  )}
                />
              )}
            <div
              className={classNames(
                "rounded-full",
                depth === 0 ? "w-2 h-2" : "w-1.5 h-1.5"
              )}
            />
            <p
              className={classNames(
                "group-hover:text-indigo-600 transition-colors duration-150",
                depth === 0 
                  ? "text-base font-semibold text-gray-700" 
                  : (ddql.questions?.length > 0 || ddql.children?.length > 0)
                    ? "text-sm font-semibold text-gray-600"
                    : "text-sm font-normal text-gray-500"
              )}
            >
              {ddql.name}
            </p>
          </div>

          <div className={classNames(
            "flex items-center gap-x-3 text-xs",
            (ddql.questions?.length > 0 || ddql.children?.length > 0)
              ? "text-gray-500 font-medium"
              : "text-gray-300"
          )}>
            {ddql.questions?.length > 0 && (
              <span>{ddql.questions.length} questions</span>
            )}
            {ddql.children?.length > 0 && (
              <span>{ddql.children.length} sublists</span>
            )}
          </div>
        </div>
      </NavLink>

      {!isCollapsed && (
        <div
          className={classNames(
            "border-l-2 border-gray-100",
            depth > 0 ? marginClasses[depth] : "ml-6"
          )}
        >
          {ddql.questions?.length > 0 && (
            <div className="pl-6 py-2">
              {ddql.questions
                ?.sort((a, b) => (a.index || '').localeCompare(b.index || ''))
                .map((q, qIndex) => (
                  <Question
                    key={q.id}
                    isFirst={qIndex === 0}
                    isLast={qIndex === ddql.questions?.length - 1}
                    question={q as GqlQuestion}
                    showComments={rootDdqlStatus === DdqlStatus.Published}
                    showAnswered={rootDdqlStatus === DdqlStatus.Published}
                    showAvatar={rootDdqlStatus === DdqlStatus.Published}
                    showIndex={true}
                  />
                ))}
            </div>
          )}

          <div
            className={classNames(
              "space-y-1",
              ddql.questions?.length > 0 ? "mt-2" : ""
            )}
          >
            {ddql?.children?.map((childDdql, index) => (
              <DDQLNode
                key={childDdql.id}
                ddql={childDdql}
                depth={depth + 1}
                isLast={index === ddql.children?.length - 1}
                rootDdqlStatus={rootDdqlStatus}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
