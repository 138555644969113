import {
  ArrowRightIcon,
  ChevronRightIcon,
  PlusIcon,
} from "@heroicons/react/20/solid";
import { Card } from "../../components/Card";
import { H3 } from "../../components/Heading";
import Loading from "../../components/Loading";
import {
  AccountFragmentFragment,
  AccountQuery,
  DealStatus,
  useAccountQuery,
  useDealDashboardDetailsQuery,
  useUpdateDealMutation,
} from "../../graphql/generated";
import useGqlClient from "../../hooks/useGqlClient";
import { NavLink, useHistory } from "react-router-dom";
import CompanyLogo from "../../components/CompanyLogo";
import { formatDistanceToNowStrict, fromUnixTime } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../store/auth/slice";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { authSelectors } from "../../store/auth/selector";
import { Avatar } from "../../components/account/Avatar";
import { AppState } from "../../store";
import {
  adminRoles,
  DealRoleRestricted,
} from "../../components/DealRoleRestricted";
import { useState } from "react";
import { AddPeopleFromFirmModal } from "../access/AddPeopleFromFirm";
import { useQueryClient } from "@tanstack/react-query";
import { TripleDotMenu } from "../../components/TripleDotMenu";
import { Menu } from "@headlessui/react";
import { classNames } from "../../utils/cn";
import {
  ArchiveBoxArrowDownIcon,
  ArchiveBoxIcon,
} from "@heroicons/react/24/outline";
import { toasts } from "../../components/toasts/toasts";
import { useSession } from "@clerk/clerk-react";
import { SignupSource } from "../Signup";
import { PackageOpenIcon, TablePropertiesIcon } from "lucide-react";

export function Home() {
  return (
    <div className="flex-1 flex flex-col">
      <div className="bg-white sticky top-0 z-20 px-8 py-3 w-full shadow-sm border-b border-gray-300/80">
        <div className="flex items-center justify-between">
          <div>
            <H3>Home</H3>
            <p className="text-sm text-gray-500 leading-none"></p>
          </div>
        </div>
      </div>
      <div className="p-8 flex-1">
        <HomeContent />
      </div>
    </div>
  );
}

function HomeContent() {
  const client = useGqlClient();
  const accountQuery = useAccountQuery(client);
  const history = useHistory();
  const dispatch = useDispatch();
  const { session } = useSession();

  if (accountQuery.error) {
    return (
      <div>
        <p className="text-gray-700 font-bold">Something went wrong</p>
      </div>
    );
  }

  if (accountQuery.isLoading || !accountQuery.data) {
    return <Loading />;
  }

  const signUpSource =
    (session?.user.unsafeMetadata.signupSource as SignupSource) ??
    SignupSource.signupPage;

  const deals = [...accountQuery.data.account.deals];

  return (
    <div>
      <SignupBanner
        signupSource={signUpSource}
        account={accountQuery.data.account}
      />
      <div>
        <div className="flex items-center justify-between">
          <H3>My deals</H3>
          <NavLink
            to="/home/archived"
            onClick={(e) => {
              dispatch(actions.setActiveDealId({ id: undefined }));
            }}
          >
            <p className="text-sm text-persian-500 font-semibold hover:text-persian-700">
              View archived deals
            </p>
          </NavLink>
        </div>
        {deals.length === 0 ? (
          <div className="mt-2">
            <Card padding="m">
              <div className="flex items-center justify-center flex-col">
                <PackageOpenIcon className="h-8 w-8 text-gray-400" />
                <p className="font-semibold text-gray-600">No live deals</p>
              </div>
            </Card>
          </div>
        ) : null}
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 mt-2 gap-4">
          {deals
            .sort((a, b) => {
              return a.createdAt > b.createdAt ? -1 : 1;
            })
            .filter((d) => d.status === DealStatus.Active)
            .map((deal) => {
              return (
                <div key={deal.id} className="cursor-pointer text-left">
                  <DealCard deal={deal} />
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

function SignupBanner(props: {
  signupSource: SignupSource;
  account: AccountFragmentFragment | undefined;
}) {
  if (props.signupSource !== SignupSource.externalInvite) {
    return null;
  }

  return (
    <div className="flex items-center justify-between w-full p-3 rounded-md bg-gradient-to-br from-persian-950/90 to-persian-950/80 mb-6 border-2 shadow-sm border-persian-200">
      <div>
        <p className="text-md text-gray-50 font-semibold">Sign up to Liquid</p>
        <p className="text-sm text-gray-50">
          Find out why more and more firms are switching to Liquid to run their
          deals
        </p>
      </div>
      <a
        href={`mailto:hello@liquidacquire.com?subject=${encodeURIComponent(
          "I'm interested in Liquid"
        )}`}
        target="_blank"
        rel="noreferrer"
      >
        <p className="text-white font-semibold text-sm  group flex items-center">
          Talk to us
          <ArrowRightIcon className="text-white w-4 h-4 group-hover:ml-2 ml-0.5 transition-all" />
        </p>
      </a>
    </div>
  );
}

export function DealCard(props: { deal: AccountQuery["account"]["deals"][0] }) {
  const deal = props.deal;
  const dealLastSeen = useSelector((state: AppState) =>
    authSelectors.dealLastSeen(state, deal.id)
  );

  const lastSeen = dealLastSeen ? dealLastSeen : deal.createdAt;

  const client = useGqlClient();
  const queryClient = useQueryClient();
  const updateDeal = useUpdateDealMutation(client);
  const history = useHistory();
  const dispatch = useDispatch();

  return (
    <Card padding="m" key={deal.id}>
      <div
        className="flex items-start justify-between"
        onClick={() => {
          dispatch(actions.setActiveDealId({ id: deal.id }));
          history.push("/deal/dashboard");
        }}
      >
        <div className="flex items-center">
          <CompanyLogo
            logo={deal.company.logo}
            name={deal.company.name}
            bgColor={deal.company.logoColor}
            withShadow={false}
          />
          <div className="ml-2">
            <div>
              <p className="font-semibold leading-tight text-gray-700 text-sm">
                {deal.company.name}
              </p>
            </div>
            <p className="text-xs text-gray-400">
              Last viewed {formatDistanceToNowStrict(fromUnixTime(lastSeen))}
            </p>
          </div>
        </div>
        <DealRoleRestricted dealId={deal.id} roles={adminRoles}>
          <TripleDotMenu width="w-40">
            <Menu.Items>
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(actions.setActiveDealId({ id: deal.id }));
                      history.push("/deal/settings/custom-properties");
                    }}
                    className={classNames(
                      active ? "bg-gray-50 text-gray-700" : "",
                      "px-3 py-2 flex items-center gap-x-2 text-gray-500 cursor-pointer"
                    )}
                  >
                    <TablePropertiesIcon className="w-4 h-4" />
                    <p className="text-sm font-semibold">Custom properties</p>
                  </div>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      const updateStatus =
                        deal.status === DealStatus.Active
                          ? DealStatus.Archived
                          : DealStatus.Active;

                      updateDeal.mutate(
                        {
                          input: {
                            id: deal.id,
                            status: updateStatus,
                          },
                        },
                        {
                          onError: () => {
                            toasts.error(
                              `${
                                updateStatus === DealStatus.Active
                                  ? "Unarchive"
                                  : "Archive"
                              } deal failed`
                            );
                          },
                          onSuccess: () => {
                            toasts.info(
                              `${
                                updateStatus === DealStatus.Active
                                  ? "Deal unarchived"
                                  : "Deal archived"
                              }`
                            );
                            queryClient.invalidateQueries({
                              queryKey: ["Account"],
                            });
                          },
                        }
                      );
                    }}
                    className={classNames(
                      active ? "bg-gray-50 text-orange-800" : "",
                      "font-semibold block px-3 py-1 text-sm leading-6 text-orange-700/90 cursor-pointer"
                    )}
                  >
                    {deal.status === DealStatus.Archived ? (
                      <>
                        <ArchiveBoxArrowDownIcon className="w-4 h-4 mr-2  inline-block" />
                        Unarchive deal
                      </>
                    ) : (
                      <>
                        <ArchiveBoxIcon className="w-4 h-4 mr-2  inline-block" />
                        Archive deal
                      </>
                    )}
                  </div>
                )}
              </Menu.Item>
            </Menu.Items>
          </TripleDotMenu>
        </DealRoleRestricted>
      </div>
      <DealDetails id={deal.id} />
    </Card>
  );
}

function DealDetails(props: { id: string }) {
  const client = useGqlClient();

  const account = useSelector(authSelectors.account);
  const dealLastSeen = useSelector((state: AppState) =>
    authSelectors.dealLastSeen(state, props.id)
  );

  const [openModal, setOpenModal] = useState<"add_people" | "">("");

  const dispatch = useDispatch();
  const history = useHistory();
  const queryClient = useQueryClient();

  const dealDetailsQuery = useDealDashboardDetailsQuery(client, {
    dealId: props.id,
  });

  if (dealDetailsQuery.error) {
    return (
      <div>
        <p className="font-semibold text-gray-500">Something went wrong</p>
      </div>
    );
  }

  if (dealDetailsQuery.isPending || !dealDetailsQuery.data || !account) {
    return (
      <div className="mt-2 flex gap-x-4">
        <div className=" flex-1">
          <Skeleton style={{ width: "30%" }} />
          <Skeleton height={30} style={{ width: "100%" }} /> d
        </div>
        <div className=" flex-1">
          <Skeleton style={{ width: "30%" }} />
          <Skeleton height={30} style={{ width: "100%" }} />
        </div>
      </div>
    );
  }

  const lastSeen = dealLastSeen
    ? dealLastSeen
    : dealDetailsQuery.data.deal.createdAt;

  return (
    <div className="mt-4 gap-x-6 flex items-start flex-col">
      <div className="flex flex-col w-full">
        <div className="flex-1 ">
          <p className="text-sm font-semibold text-gray-600">Overview</p>
          <div className="grid mt-2 grid-cols-2 lg:grid-cols-4 gap-y-4">
            <button
              className="text-left group"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(actions.setActiveDealId({ id: props.id }));
                history.push("/deal/documents");
              }}
            >
              <p className="font-semibold text-lg text-gray-600">
                {
                  dealDetailsQuery.data.deal.dataRoom.allFiles.filter(
                    (f) => f.createdAt > lastSeen
                  ).length
                }
              </p>
              <div className="flex items-center">
                <p className="text-xs text-gray-500 truncate">New files</p>
                <ChevronRightIcon className="w-4 text-gray-500 h-4 hidden group-hover:block" />
              </div>
            </button>

            <button
              className="text-left group"
              onClick={(e) => {
                e.stopPropagation();
                dispatch(actions.setActiveDealId({ id: props.id }));
                history.push("/deal/questions");
              }}
            >
              <p className="font-semibold text-lg text-gray-600">
                {
                  dealDetailsQuery.data.deal.questions.filter((q) => !q.answer)
                    .length
                }
              </p>
              <div className="flex items-center">
                <p className="text-xs text-gray-500 truncate">Open questions</p>
                <ChevronRightIcon className="w-4 text-gray-500 h-4 hidden group-hover:block" />
              </div>
            </button>

            <div className="text-left group">
              <p className="font-semibold text-lg text-gray-600">
                {formatDistanceToNowStrict(
                  fromUnixTime(dealDetailsQuery.data.deal.createdAt)
                )}
              </p>
              <div className="flex items-center">
                <p className="text-xs text-gray-500 truncate">Deal Duration</p>
              </div>
            </div>

            <DealRoleRestricted dealId={props.id} roles={adminRoles}>
              {dealDetailsQuery.data.deal.dealPropertyValues.map((dpv) => {
                return (
                  <div>
                    <p
                      className={`${
                        dpv.propertyValue && dpv.propertyValue.length > 20
                          ? "text-sm"
                          : dpv.propertyValue && dpv.propertyValue.length > 10
                          ? "text-base"
                          : "text-lg"
                      } font-semibold text-gray-600`}
                    >
                      {dpv.propertyValue ? dpv.propertyValue : "-"}
                    </p>
                    <p className="text-xs text-gray-500 truncate">
                      {dpv.propertyKey}
                    </p>
                  </div>
                );
              })}
            </DealRoleRestricted>
          </div>
        </div>
        <DealRoleRestricted dealId={props.id} roles={adminRoles}>
          <div className="flex-1 mt-4">
            <p className="text-sm font-semibold text-gray-600">My firm</p>
            <div className="flex mt-2 transition-all hover:space-x-0.5 -space-x-0.5">
              {dealDetailsQuery.data.deal.dealAccounts
                .filter((da) => {
                  if (!account.firm || !da.account.firm) {
                    return false;
                  }

                  return da.account.firm.id === account.firm.id;
                })
                .map((da) => {
                  return <Avatar account={da.account} />;
                })}
              <DealRoleRestricted dealId={props.id} roles={adminRoles}>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpenModal("add_people");
                  }}
                  className="w-6 h-6 z-20 bg-gray-100 hover:bg-gray-200 rounded-full flex items-center justify-center"
                >
                  <PlusIcon className="w-4 h-4 text-gray-500" />
                </button>
              </DealRoleRestricted>
            </div>
          </div>
        </DealRoleRestricted>
      </div>

      <AddPeopleFromFirmModal
        dealId={props.id}
        open={openModal === "add_people"}
        onClose={() => {
          queryClient.invalidateQueries({
            queryKey: ["DealDashboardDetails", { dealId: props.id }],
          });
          setOpenModal("");
        }}
      />
    </div>
  );
}
