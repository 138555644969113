import * as H from 'history';
import { WithMarginProp } from '@/src/styles/withMargin';
import { NavLink } from 'react-router-dom';
import { classNames } from '@/src/utils/cn';
import { match } from 'react-router';

const Link = ({
                children,
                size,
                onClick,
                mode,
                isActive,
                ...props
              }: {
  children: React.ReactNode;
  size?: string;
  mode?: 'secondary' | 'primary';
  onClick?: () => void;
  isActive?<Params extends { [K in keyof Params]?: string }>(
    match: match<Params> | null,
    location: H.Location<H.LocationState>,
  ): boolean;
} & WithMarginProp & {
  to: string;
  exact?: boolean;
}) => {
  if (mode && mode === 'secondary') {
    return (
      <NavLink
        isActive={isActive}
        {...props}
        onClick={onClick}
        key={props.to}
        className={(isActive) => {
          return classNames(
            size === 's' ? 'text-sm px-0' : 'text-base',
            'gap-x-2 -mb-px py-1.5 my-1.5 text-gray-700 font-semibold border-b-2 flex items-center',
            isActive
              ? ' text-gray-700 border-persian-950'
              : 'text-gray-700 opacity-70 hover:opacity-100 hover:border-persian-950 border-transparent',
          );
        }}
      >
        {children}
      </NavLink>
    );
  }

  return (
    <NavLink
      {...props}
      key={props.to}
      className={(isActive) => {
        return classNames(
          size === 's' ? 'text-sm px-0' : 'text-base',
          'gap-x-2 px-3 py-1.5 my-1.5 hover:bg-gray-300/80 font-semibold rounded-md flex items-center',
          isActive ? 'bg-gray-300/80 text-gray-800' : 'text-gray-800',
        );
      }}
    >
      {children}
    </NavLink>
  );
};

export default Link;