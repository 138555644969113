import { classNames } from "@/src/utils/cn";
import { fileWrapper } from "./NewFilesUploader";
import { LocalFileIcon } from "../../icons/LocalFileIcon";
import { TextInput } from "../../tailwind/TextInput";
import { Dispatch, SetStateAction } from "react";
import { formatFileSize } from "@/src/utils/formatFileSize";
import { DataRoomPermission } from "@/src/graphql/generated";
import { adminRoles, DealRoleRestricted } from "../../DealRoleRestricted";
import Dropdown from "../../tailwind/Dropdown";
import { TrashIcon } from "@heroicons/react/24/solid";
import { FileWrapStatus } from "./fileWrapStatus";

interface LocalFileItemProps {
  file: fileWrapper;
  onFilesChange: Dispatch<SetStateAction<fileWrapper[]>>;
  permissions: DataRoomPermission[];
  uploadFile: (file: fileWrapper) => void;
}

export function LocalFileItem(props: LocalFileItemProps) {
  const file = props.file;
  return (
    <div
      key={file.id}
      className={classNames(
        "relative flex-wrap py-3 my-2 group gap-x-2 justify-between items-center flex border-b border-gray-200",
        file.status === "complete" ? "opacity-80" : ""
      )}
    >
      <div className="flex w-96">
        <LocalFileIcon fileType={file.file.type} />
        <div className="ml-3">
        <div className="flex items-center">
          {file.status === "pending" ? (
              <div className="w-80">
                <TextInput
                  value={file.name}
                  inputSize="s"
                onChange={(e) => {
                  const value = e.currentTarget.value;
                  props.onFilesChange((prev) => {
                    return prev.map((f) => {
                      if (f.id === file.id) {
                        return {
                          ...f,
                          name: value,
                        };
                      }

                      return f;
                    });
                  });
                }}
              />
              <span className="text-xs text-gray-500 leading-none ml-2 inline">
                {formatFileSize(file.file.size)}
              </span>
            </div>
          ) : (
            <p className="font-semibold text-sm text-gray-700 truncate">
              {file.name}
              <span className="text-xs text-gray-500 mt-1.5 leading-none ml-2">
                {formatFileSize(file.file.size)}
              </span>
            </p>
            )}
          </div>
        </div>
      </div>
      {file.status === "pending" ? (
        <div className="flex items-center overflow-visible gap-x-2 group">
          {props.permissions.length > 0 ? (
            <DealRoleRestricted roles={adminRoles}>
              <Dropdown
                hideSelectedOptionDescription
                variant="light"
                selectedOption={file.selectedPermission}
                onSelect={(option) => {
                  props.onFilesChange((prev) => {
                    return prev.map((f) => {
                      if (f.id === file.id) {
                        return {
                          ...f,
                          selectedPermission: option,
                        };
                      }

                      return f;
                    });
                  });
                }}
                options={props.permissions.map((permission) => {
                  return {
                    value: permission.id,
                    label: permission.name,
                    description: permission.description,
                  };
                })}
              />
            </DealRoleRestricted>
          ) : null}
          <button
            type="button"
            className="text-gray-400 hover:text-gray-500"
            onClick={() => {
              props.onFilesChange((prev) => {
                return prev.filter((f) => f.id !== file.id);
              });
            }}
          >
            {file.status === "pending" && (
              <TrashIcon className="w-5 h-5 text-gray-500/80 hover:text-gray-700 cursor-pointer opacity-0 group-hover:opacity-100 transition-all duration-200 ease-in-out" />
            )}
          </button>
        </div>
      ) : (
        <FileWrapStatus
          fileWrap={file}
          onRetry={() => {
            props.uploadFile(file);
          }}
        />
      )}
    </div>
  );
}
