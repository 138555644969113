import { FileIcon } from '@/src/components/FileIcon';
import Loading from '@/src/components/Loading';
import { TripleDotMenu } from '@/src/components/TripleDotMenu';
import { DataRoomFolderFragmentFragment } from '@/src/graphql/generated';
import { classNames } from '@/src/utils/cn';
import { Menu } from '@headlessui/react';
import { FolderOpenIcon } from '@heroicons/react/24/outline';
import { FolderIcon } from '@heroicons/react/24/solid';
import { formatDistanceToNowStrict, fromUnixTime } from 'date-fns';
import { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

interface FilesProps {
  folder?: DataRoomFolderFragmentFragment;
  isLoading: boolean;
  error?: unknown;
}

export function Files(props: FilesProps) {
  const history = useHistory();
  const location = useLocation();

  const [deleteFileId, setDeleteFileId] = useState<string>('');
  const [editFileId, setEditFileId] = useState<string>('');
  const [moveFileId, setMoveFileId] = useState<string>('');

  if (props.error) {
    return (
      <div>
        <p>Something went wrong</p>
      </div>
    );
  }

  if (props.isLoading || !props.folder) {
    return <Loading />;
  }

  if (props.folder.files.length === 0 && props.folder.folders.length === 0) {
    return (
      <div className="h-96 flex justify-center items-center flex-col">
        <FolderOpenIcon className="h-12 w-12 text-gray-500/70" />
        <p className="m-0 font-semibold text-gray-700">
          You haven't uploaded any files yet
        </p>
        <p className="text-sm text-gray-500">
          Files and folders you create will appear here
        </p>
      </div>
    );
  }

  return (
    <>
      <table className="table-fixed w-full box-border text-left border-collapse block">
        <tbody className="w-full table">
        <tr>
          <th
            className="text-sm py-2 px-3 text-gray-500/80  truncate"
            style={{ width: '100%' }}
          >
            Name
          </th>

          <th
            className="text-sm py-2 px-3 text-gray-500/80 hidden xl:table-cell truncate"
            style={{ minWidth: 140 }}
          >
            Created by
          </th>
          <th
            className="text-sm py-2 px-3 text-gray-500/80 hidden xl:table-cell truncate"
            style={{ minWidth: 130 }}
          >
            Last updated
          </th>
        </tr>
        {props.folder.folders
          .sort((a, b) => {
            if (a.dealGroup && !b.dealGroup) {
              return -1;
            }

            if (!a.dealGroup && b.dealGroup) {
              return 1;
            }

            return 0;
          })
          .map((folder) => {
            return (
              <tr
                key={folder.id}
                className="cursor-pointer hover:bg-gray-50 w-full table-row  group"
                onClick={() => {
                  history.push(`/drive/folder/` + folder.id);
                }}
              >
                <td className="w-full py-2 px-3">
                  <div className="flex items-center gap-x-2">
                    <FolderIcon
                      className={
                        folder.dealGroup
                          ? 'text-orange-800/70'
                          : 'text-blue-700/70'
                      }
                      width={20}
                      height={20}
                    />
                    <p className="text-sm font-semibold text-gray-600">
                      {folder.name}
                    </p>
                  </div>
                </td>

                <td className="py-2 px-3 hidden xl:table-cell truncate">
                  <p className="text-sm text-gray-500">
                    {folder.createdBy.name}
                  </p>
                </td>
                <td className="py-2 px-3 hidden xl:table-cell">
                  <p className="text-sm text-gray-500">
                    {formatDistanceToNowStrict(
                      fromUnixTime(folder.createdAt),
                      {
                        addSuffix: true,
                      },
                    )}
                  </p>
                </td>

                <td className="py-2 px-3 hidden xl:table-cell">
                  <TripleDotMenu>
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            const params = new URLSearchParams({
                              deleteFolder: folder.id,
                            });
                            history.replace({
                              pathname: location.pathname,
                              search: params.toString(),
                            });
                          }}
                          className={classNames(
                            active ? 'bg-gray-50 text-red-700' : '',
                            'block px-3 py-1 text-sm leading-6 text-red-600 cursor-pointer',
                          )}
                        >
                          Delete
                        </div>
                      )}
                    </Menu.Item>
                  </TripleDotMenu>
                </td>
              </tr>
            );
          })}
        {props.folder.files.map((file) => {
          return (
            <tr
              key={file.id}
              className="cursor-pointer hover:bg-gray-50 w-full table-row  group"
              onClick={() => {
                if (!props.folder) {
                  return;
                }
                const link = `/drive/folder/${props.folder.id}/file/${file.id}`;
                history.push(link);
              }}
            >
              <td className="py-2 px-3">
                <div className="flex items-center gap-x-2">
                  <FileIcon fileType={file.fileType} />
                  <p className="text-gray-600 font-semibold text-sm">
                    {file.name}
                  </p>
                </div>
              </td>

              <td className="py-2 px-3 hidden xl:table-cell">
                <p className="text-sm text-gray-500">{file.createdBy.name}</p>
              </td>
              <td className="py-2 px-3 hidden xl:table-cell">
                <p className="text-sm text-gray-500">
                  {formatDistanceToNowStrict(fromUnixTime(file.updatedAt), {
                    addSuffix: true,
                  })}
                </p>
              </td>

              <td className="py-2 px-3 hidden xl:table-cell">
                <TripleDotMenu>
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          setEditFileId(file.id);
                        }}
                        className={classNames(
                          active ? 'bg-gray-50 text-blue-700' : '',
                          'block px-3 py-1 text-sm leading-6 text-blue-600 cursor-pointer',
                        )}
                      >
                        Edit
                      </div>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          setMoveFileId(file.id);
                        }}
                        className={classNames(
                          active ? 'bg-gray-50 text-blue-700' : '',
                          'block px-3 py-1 text-sm leading-6 text-blue-600 cursor-pointer',
                        )}
                      >
                        Move
                      </div>
                    )}
                  </Menu.Item>
                  <Menu.Item>
                    {({ active }) => (
                      <div
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeleteFileId(file.id);
                        }}
                        className={classNames(
                          active ? 'bg-gray-50 text-red-700' : '',
                          'block px-3 py-1 text-sm leading-6 text-red-600 cursor-pointer',
                        )}
                      >
                        Delete
                      </div>
                    )}
                  </Menu.Item>
                </TripleDotMenu>
              </td>
            </tr>
          );
        })}
        </tbody>
      </table>
      {/* <DeleteFile
        open={deleteFileId !== ""}
        dataRoomFileId={deleteFileId}
        onClose={() => {
          setDeleteFileId("");
        }}
      />
      <EditFile
        dataRoomFileId={editFileId}
        open={editFileId !== ""}
        onClose={() => {
          setEditFileId("");
        }}
      />
      <MoveFileModal
        dataRoomFileId={moveFileId}
        open={moveFileId !== ""}
        onClose={() => {
          setMoveFileId("");
        }}
      /> */}
    </>
  );
}
