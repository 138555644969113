import { FileIcon } from "@/src/components/FileIcon";
import { Loader } from "@/src/components/Loading";
import { DeepSearchThreadMessageUpdatedData } from "@/src/contexts/websockets";
import {
  DataRoomFile,
  DealThreadMessageRole,
  DealThreadMessageStatus,
  DeepSearchFileReferenceInput,
  DeepSearchFileStatus,
  DeepSearchQuery,
  DeepSearchThreadMessageContentFileReference,
  DeepSearchThreadMessageFileQuery,
  FileType,
  useAddDeepSearchFileReferenceMutation,
  useCreateDeepSearchFileMutation,
  useDeepSearchQuery,
  useDeepSearchThreadMessageFileQuery,
} from "@/src/graphql/generated";
import { AppState } from "@/src/store";
import { deepSearchSelectors } from "@/src/store/deep-search/selector";
import { classNames } from "@/src/utils/cn";
import { animated, SpringValue } from "@react-spring/web";
import {
  BotIcon,
  ChevronUpIcon,
  ChevronDownIcon,
  PlusIcon,
  CheckIcon,
  FilePlusIcon,
  ListPlusIcon,
} from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Markdown from "react-markdown";
import { ScrollContext } from "./Assistant";
import useGqlClient from "@/src/hooks/useGqlClient";
import { useQueryClient, UseQueryResult } from "@tanstack/react-query";
import {
  Reference,
  ReferenceSlideOver,
} from "@/src/components/ReferenceSlideOver";
import { hashRectsOnPage } from "@/src/components/document_viewers/utils";
import { toasts } from "@/src/components/toasts/toasts";
export function AssistantMessage(props: {
  deepSearchId: string;
  deepThreadMessageId: string;
  style: {
    transform: SpringValue<string>;
    opacity: SpringValue<number>;
  };
  index: number;
  isLast: boolean;
}) {
  const message = useSelector((state: AppState) =>
    deepSearchSelectors.deepThreadMessage(
      state,
      props.deepSearchId,
      props.deepThreadMessageId
    )
  );

  if (!message) return null;

  if (
    message.deepSearchThreadMessageContent.role === DealThreadMessageRole.Ai
  ) {
    return (
      <AiMessage message={message} style={props.style} isLast={props.isLast} />
    );
  }

  return (
    <animated.div
      style={props.style}
      key={message.deepSearchThreadMessageID}
      className={classNames("flex items-center justify-end space-x-2")}
    >
      <div className="flex items-center gap-x-2">
        <div className="bg-persian-100/70 max-w-xs shadow rounded-md p-2 my-2">
          <p className="text-sm text-gray-700">
            {message.deepSearchThreadMessageContent.text.value}
          </p>
        </div>
      </div>
    </animated.div>
  );
}

function AiMessage(props: {
  message: DeepSearchThreadMessageUpdatedData;
  style: {
    transform: SpringValue<string>;
    opacity: SpringValue<number>;
  };
  isLast: boolean;
}) {
  const { triggerScroll } = useContext(ScrollContext);
  const message = props.message;
  const [displayedText, setDisplayedText] = useState("");
  const [isRevealing, setIsRevealing] = useState(false);

  useEffect(() => {
    if (
      message.deepSearchThreadMessageContent.text.value.length >
      displayedText.length
    ) {
      setIsRevealing(true);
    }
  }, [message.deepSearchThreadMessageContent.text.value, displayedText]);

  // 3. Reveal text character by character
  useEffect(() => {
    let intervalId: NodeJS.Timeout;

    if (isRevealing && props.isLast) {
      intervalId = setInterval(() => {
        setDisplayedText((prev) => {
          // If we've caught up, stop
          if (
            prev.length >=
            message.deepSearchThreadMessageContent.text.value.length
          ) {
            clearInterval(intervalId);
            setIsRevealing(false);
            return prev;
          }
          // Reveal the next character
          return (
            prev +
            message.deepSearchThreadMessageContent.text.value[prev.length]
          );
        });
        triggerScroll();
      }, 15); // Adjust typing speed (ms) as needed
    } else {
      setDisplayedText(message.deepSearchThreadMessageContent.text.value);
    }

    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [isRevealing, message.deepSearchThreadMessageContent.text.value]);

  if (
    message.deepSearchThreadMessageContent.status ===
    DealThreadMessageStatus.Failed
  ) {
    return (
      <animated.div
        style={props.style}
        key={message.deepSearchThreadMessageID}
        className="flex items-end justify-start space-x-2 my-2"
      >
        <BotIcon className="w-4 h-4 text-gray-500" />
        <div className="bg-gray-50 max-w-xs shadow rounded-md p-2 ">
          <p className="text-sm text-gray-500">Failed to generate response</p>
        </div>
      </animated.div>
    );
  }

  return (
    <animated.div
      style={props.style}
      key={message.deepSearchThreadMessageID}
      className="flex items-end justify-start space-x-2 my-2"
    >
      <BotIcon className="w-4 h-4 text-gray-500" />
      <div className="bg-gray-50 w-2/3 shadow rounded-md p-2 ">
        {message.deepSearchThreadMessageContent.text.value === "" ? (
          <Loader width={16} height={16} />
        ) : (
          <>
            <p className="whitespace-pre-wrap text-gray-700 text-sm">
              <Markdown>{displayedText}</Markdown>
            </p>
          </>
        )}
        <div className="space-y-2 mt-2">
          {message.deepSearchThreadMessageFileIds.map((id, index) => (
            <div key={id}>
              <DeepSearchThreadMessageFile
                id={id}
                deepSearchId={props.message.deepSearchId}
              />
            </div>
          ))}
        </div>
      </div>
    </animated.div>
  );
}

function DeepSearchThreadMessageFile(props: {
  id: string;
  deepSearchId: string;
}) {
  const client = useGqlClient();
  const deepSearchThreadMessageFileQuery = useDeepSearchThreadMessageFileQuery(
    client,
    {
      id: props.id,
    },
    {
      refetchInterval(query) {
        if (
          query.state?.data?.deepSearchThreadMessageFile.status ===
          DeepSearchFileStatus.Searching
        ) {
          return 1000;
        }
        return false;
      },
    }
  );

  return (
    <div className="p-2 rounded-md border border-gray-200 bg-white text-wrap">
      <DeepSearchThreadMessageFileContent
        query={deepSearchThreadMessageFileQuery}
        deepSearchId={props.deepSearchId}
      />
    </div>
  );
}

function DeepSearchThreadMessageFileContent(props: {
  query: UseQueryResult<DeepSearchThreadMessageFileQuery, unknown>;
  deepSearchId: string;
}) {
  const [addingReferencesLoading, setAddingReferencesLoading] = useState(false);
  const [showReferences, setShowReferences] = useState(false);
  const [selectedReference, setSelectedReference] = useState<
    Reference | undefined
  >(undefined);

  const client = useGqlClient();
  const queryClient = useQueryClient();
  const deepSearchQuery = useDeepSearchQuery(client, {
    id: props.deepSearchId,
  });

  const createDeepSearchFile = useCreateDeepSearchFileMutation(client);
  const addDeepSearchFileReference =
    useAddDeepSearchFileReferenceMutation(client);

  if (props.query.error) {
    return (
      <div>
        <p className="text-xs text-gray-700">Failed to load file</p>
      </div>
    );
  }

  if (props.query.isLoading || !props.query.data) {
    return (
      <div className="flex items-center gap-x-2">
        <FileIcon size="s" fileType={FileType.Other} />
        <p className="text-xs text-gray-700">Loading...</p>
      </div>
    );
  }

  const data = props.query.data;

  function createDeepSearchFileFn(references: DeepSearchFileReferenceInput[]) {
    createDeepSearchFile.mutate(
      {
        input: {
          deepSearchID: props.deepSearchId,
          dataRoomFileID: data.deepSearchThreadMessageFile.file.id,
          dataRoomFileVersionID:
            data.deepSearchThreadMessageFile.fileVersion.id,
          references,
        },
      },
      {
        onSuccess: (data) => {
          queryClient.invalidateQueries({
            queryKey: ["DeepSearch", { id: props.deepSearchId }],
          });
        },
      }
    );
  }

  function addAllReferences() {
    if (!deepSearchQuery.data) return;

    const existingDeepSearchFile = deepSearchQuery.data.deepSearch.files.find(
      (f) =>
        f.file.id === data.deepSearchThreadMessageFile.file.id &&
        f.currentVersion.id === data.deepSearchThreadMessageFile.fileVersion.id
    );

    setAddingReferencesLoading(true);
    const referencesToAdd = data.deepSearchThreadMessageFile.references.filter(
      (r) =>
        !deepSearchQuery.data?.deepSearch.files.some((f) =>
          f.references.some(
            (rf) => hashRectsOnPage(rf.rectsOnPage) === hashRectsOnPage(r.rects)
          )
        )
    );

    if (!existingDeepSearchFile) {
      createDeepSearchFileFn(referencesToAdd);
      return;
    }

    referencesToAdd.forEach((r) => {
      addDeepSearchFileReference.mutate(
        {
          input: {
            deepSearchFileID: existingDeepSearchFile.id,
            rectsOnPage: r.rects,
            pageIndex: r.pageIndex,
            quote: r.quote,
            annotationID: r.id,
          },
        },
        {
          onError: () => {
            toasts.error(`Failed to add reference ${r.quote}`);
          },
          onSuccess: () => {
            queryClient.invalidateQueries({
              queryKey: ["DeepSearch", { id: props.deepSearchId }],
            });
          },
        }
      );
    });

    setAddingReferencesLoading(false);
  }

  const isDocumentAlreadyAdded =
    deepSearchQuery.data &&
    deepSearchQuery.data.deepSearch.files.some(
      (file) =>
        file.file.id === data.deepSearchThreadMessageFile.file.id &&
        file.currentVersion.id ===
          data.deepSearchThreadMessageFile.fileVersion.id
    );

  const referencesToAdd = data.deepSearchThreadMessageFile.references.filter(
    (r) =>
      !deepSearchQuery.data?.deepSearch.files.some((f) =>
        f.references.some(
          (rf) => hashRectsOnPage(rf.rectsOnPage) === hashRectsOnPage(r.rects)
        )
      )
  );

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-x-2">
          <FileIcon
            size="s"
            fileType={data.deepSearchThreadMessageFile.file.fileType}
          />
          <p className="text-sm font-semibold text-gray-700">
            {data.deepSearchThreadMessageFile.file.name}
          </p>
        </div>

        <div>
          <DeepSearchThreadMessageFileStatus
            status={data.deepSearchThreadMessageFile.status}
            references={data.deepSearchThreadMessageFile.references}
            showReferences={showReferences}
            toggleShowReferences={() => setShowReferences(!showReferences)}
          />
        </div>
      </div>

      <div className="mt-1 flex items-center gap-x-2">
        {isDocumentAlreadyAdded ? (
          <div className="flex items-center gap-x-1 mt-2 px-2 py-1 rounded-full border border-gray-300 bg-white text-xs text-gray-700 ">
            <CheckIcon className="w-3 h-3 text-gray-700" />
            Document added
          </div>
        ) : (
          <button className="flex items-center gap-x-1 mt-2 px-2 py-1 rounded-full border border-gray-300 bg-gray-50 text-xs text-gray-700 hover:shadow-sm hover:border-gray-500 hover:bg-gray-50 transition-all duration-300">
            <FilePlusIcon className="w-3 h-3 text-gray-700" />
            Add document
          </button>
        )}

        {referencesToAdd.length > 0 ? (
          <button
            onClick={() => {
              addAllReferences();
            }}
            className="flex items-center gap-x-1 mt-2 px-2 py-1 rounded-full border border-gray-300 bg-gray-50 text-xs text-gray-700 hover:shadow-sm hover:border-gray-500 hover:bg-gray-50 transition-all duration-300"
          >
            <ListPlusIcon className="w-3 h-3 text-gray-700" />
            {addingReferencesLoading ? "Adding..." : "Add all references"}
          </button>
        ) : null}
      </div>

      {showReferences ? (
        <div className="mt-3 space-y-2">
          {data.deepSearchThreadMessageFile.references.map((r) => {
            return (
              <DeepSearchThreadMessageFileReference
                key={r.id}
                reference={r}
                setSelectedReference={setSelectedReference}
                data={data}
                deepSearch={deepSearchQuery.data?.deepSearch}
              />
            );
          })}
        </div>
      ) : null}

      <ReferenceSlideOver
        open={!!selectedReference}
        onClose={() => setSelectedReference(undefined)}
        reference={selectedReference}
      />
    </div>
  );
}

function DeepSearchThreadMessageFileReference(props: {
  reference: DeepSearchThreadMessageContentFileReference;
  setSelectedReference: (reference: Reference) => void;
  data: DeepSearchThreadMessageFileQuery;
  deepSearch?: DeepSearchQuery["deepSearch"];
}) {
  const client = useGqlClient();
  const createDeepSearchFile = useCreateDeepSearchFileMutation(client);
  const addDeepSearchFileReference =
    useAddDeepSearchFileReferenceMutation(client);
  const queryClient = useQueryClient();

  const deepSearchFile = props.deepSearch?.files.find(
    (file) =>
      file.file.id === props.data.deepSearchThreadMessageFile.file.id &&
      file.currentVersion.id ===
        props.data.deepSearchThreadMessageFile.fileVersion.id
  );

  const isReferenceAlreadyAdded =
    deepSearchFile &&
    deepSearchFile.references.some(
      (r) =>
        hashRectsOnPage(r.rectsOnPage) ===
        hashRectsOnPage(props.reference.rects)
    );

  function addReference(deepSearchFileID: string) {
    addDeepSearchFileReference.mutate(
      {
        input: {
          deepSearchFileID,
          rectsOnPage: props.reference.rects,
          pageIndex: props.reference.pageIndex,
          quote: props.reference.quote,
          annotationID: props.reference.id,
        },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({
            queryKey: ["DeepSearch", { id: props.deepSearch?.id ?? "" }],
          });
        },
        onError: (error) => {
          toasts.error("Failed to add reference");
        },
      }
    );
  }

  return (
    <button
      key={props.reference.id}
      className="p-1.5 rounded-md border group border-gray-200 bg-gray-50 text-left w-full hover:shadow-sm hover:border-gray-300"
      onClick={() =>
        props.setSelectedReference({
          file: props.data.deepSearchThreadMessageFile.file as DataRoomFile,
          fileVersionId: props.data.deepSearchThreadMessageFile.fileVersion.id,
          pageIndex: props.reference.pageIndex,
          quote: props.reference.quote,
          rectsOnPage: props.reference.rects,
        })
      }
    >
      <p className="text-xs text-gray-500/80">
        Page {props.reference.pageIndex + 1}
      </p>
      <p className="text-xs font-semibold text-gray-700">
        {props.reference.quote}
      </p>

      {!isReferenceAlreadyAdded ? (
        <button
          className="p-1 px-1.5 border border-gray-200 hover:border-gray-400 hover:shadow-sm hover:bg-gray-50 rounded-full bg-white flex items-center gap-x-1 text-xs mt-0 group-hover:mt-2 h-0 opacity-0 group-hover:h-auto group-hover:opacity-100 transition-all duration-300"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            if (deepSearchFile) {
              addReference(deepSearchFile.id);
              return;
            }

            createDeepSearchFile.mutate(
              {
                input: {
                  deepSearchID: props.deepSearch?.id ?? "",
                  dataRoomFileID:
                    props.data.deepSearchThreadMessageFile.file.id,
                  dataRoomFileVersionID:
                    props.data.deepSearchThreadMessageFile.fileVersion.id,
                  references: [props.reference],
                },
              },
              {
                onSuccess: (data) => {
                  queryClient.invalidateQueries({
                    queryKey: [
                      "DeepSearch",
                      { id: props.deepSearch?.id ?? "" },
                    ],
                  });
                },
              }
            );
          }}
        >
          <PlusIcon className="w-3 h-3 text-gray-700" />
          Add reference
        </button>
      ) : (
        <div className="flex items-center gap-x-1.5 text-xs text-gray-500 mt-2">
          <CheckIcon className="w-3 h-3 text-gray-500" />
          Reference added
        </div>
      )}
    </button>
  );
}

function DeepSearchThreadMessageFileStatus(props: {
  status: DeepSearchFileStatus;
  references: DeepSearchThreadMessageContentFileReference[];
  showReferences: boolean;
  toggleShowReferences: () => void;
}) {
  if (props.status === DeepSearchFileStatus.Searching) {
    return (
      <p className="text-xs text-gray-700">
        Searching document for references...
      </p>
    );
  }

  if (props.status === DeepSearchFileStatus.Complete) {
    return (
      <button
        onClick={() => {
          if (props.references.length === 0) {
            return;
          }

          props.toggleShowReferences();
        }}
        className="flex items-center gap-x-2"
      >
        <p className="text-xs text-gray-700">
          Found {props.references.length} references
        </p>
        {props.showReferences ? (
          <ChevronUpIcon className="w-4 h-4 text-gray-700" />
        ) : (
          <ChevronDownIcon className="w-4 h-4 text-gray-700" />
        )}
      </button>
    );
  }

  return null;
}

function LocalFilePill(props: { name: string; fileType: string }) {
  return (
    <div className="cursor-pointer hover:border-gray-400 bg-white hover:shadow-sm relative flex items-center rounded-2xl border px-2 py-1">
      <FileIcon size="s" fileType={props.fileType as FileType} />
      <p className="text-xs ml-1 text-gray-800 truncate">{props.name}</p>
    </div>
  );
}
