import { Card } from '@/src/components/Card';
import { Avatar } from '@/src/components/account/Avatar';
import { formatFirmAccountType } from '@/src/utils/enums';
import {
  FirmAccountType,
  FirmQuery,
  useCreateFirmInviteMutation,
  useDeleteFirmInviteMutation,
  useFirmQuery,
} from '@/src/graphql/generated';
import { PlusIcon } from '@heroicons/react/20/solid';
import { TripleDotMenu } from '@/src/components/TripleDotMenu';
import { Menu } from '@headlessui/react';
import { toasts } from '@/src/components/toasts/toasts';
import { classNames } from '@/src/utils/cn';
import Loading from '@/src/components/Loading';
import useGqlClient from '@/src/hooks/useGqlClient';
import { useSelector } from 'react-redux';
import { authSelectors } from '@/src/store/auth/selector';
import { useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import Dropdown, { Option } from '@/src/components/tailwind/Dropdown';
import { AnimatedModal } from '@/src/components/AnimatedModal';
import { H3 } from '@/src/components/Heading';
import { CloseIcon } from '@/src/components/CloseIcon';
import { TextInput } from '@/src/components/tailwind/TextInput';
import { TextArea } from '@/src/components/tailwind/TextArea';
import { Button } from '@/src/components/tailwind/Button';

export default function Members() {
  const account = useSelector(authSelectors.account);
  const client = useGqlClient();

  const deleteFirmInvite = useDeleteFirmInviteMutation(client);
  const firmQuery = useFirmQuery(client, {
    id: account?.firm?.id ?? '',
  });

  const [showInviteModal, setShowInviteModal] = useState(false);

  if (firmQuery.error) {
    return (
      <div>
        <p>Something went wrong</p>
      </div>
    );
  }

  if (firmQuery.isPending || !firmQuery.data) {
    return <Loading />;
  }

  const firm = firmQuery.data.firm;
  return (
    <div>
      <div className="w-full xl:w-1/2">
        <Card padding="m">
          <p className="font-semibold text-gray-800">People</p>
          <div className="mt-3">
            {firm.accounts.map((account) => {
              return (
                <div key={account.id} className="my-3">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <Avatar account={account} />

                      <div className="ml-2">
                        <p className="text-sm font-semibold text-gray-700 leading-tight">
                          {account.name}
                        </p>
                        <p className="text-sm text-gray-500 leading-tight">
                          {account.email}
                        </p>
                      </div>
                    </div>
                    <div className="flex items-center">
                      <p className="text-sm text-gray-500 mt-0.5">
                        {formatFirmAccountType(account.firmAccountType)}
                      </p>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="flex items-center mt-5 justify-between">
            <p className="font-semibold  text-gray-800">Invites</p>
            {account?.firmAccountType === FirmAccountType.Admin ? (
              <button
                onClick={() => {
                  setShowInviteModal(true);
                }}
                className="text-indigo-500 text-sm hover:text-indigo-600 font-semibold"
              >
                <div className="flex items-center">
                  <PlusIcon className="h-4 w-4" />
                  <p>Invite</p>
                </div>
              </button>
            ) : null}
          </div>
          {firm.invites.length === 0 ? (
            <p className="text-sm text-gray-500">No invites</p>
          ) : null}
          {firm.invites.map((invite) => {
            return (
              <div
                key={invite.id}
                className="flex items-center justify-between mt-3"
              >
                <div>
                  <p className="text-sm font-semibold text-gray-700 leading-tight">
                    {invite.email}
                  </p>
                </div>
                <div className="flex items-center gap-x-1.5">
                  <p className="text-sm text-gray-500">
                    {formatFirmAccountType(invite.role)}
                  </p>
                  <TripleDotMenu>
                    <Menu.Item>
                      {({ active }) => (
                        <div
                          onClick={(e) => {
                            deleteFirmInvite.mutate(
                              {
                                id: invite.id,
                              },
                              {
                                onSuccess: () => {
                                  firmQuery.refetch();
                                  toasts.success('Invite deleted');
                                },
                                onError: () => {
                                  toasts.error('Failed to delete invite');
                                },
                              },
                            );
                          }}
                          className={classNames(
                            active ? 'bg-gray-50 text-red-700' : '',
                            'block px-3 py-1 text-sm leading-6 text-red-600 cursor-pointer',
                          )}
                        >
                          Delete invite
                        </div>
                      )}
                    </Menu.Item>
                  </TripleDotMenu>
                </div>
              </div>
            );
          })}
        </Card>
      </div>
      <CreateFirmInviteModal
        firm={firm}
        open={showInviteModal}
        onClose={() => {
          setShowInviteModal(false);
        }}
      />
    </div>
  );
};

const firmAccounTypeOptions: Option[] = [
  {
    value: FirmAccountType.Admin,
    label: 'Admin',
  },
  {
    value: FirmAccountType.Staff,
    label: 'Staff',
  },
];


function CreateFirmInviteModal(props: {
  open: boolean;
  onClose: () => void;
  firm: FirmQuery['firm'];
}) {
  const queryClient = useQueryClient();
  const client = useGqlClient();
  const createFirmInvite = useCreateFirmInviteMutation(client);

  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');

  const [message, setMessage] = useState('');
  const [type, setType] = useState<Option>(firmAccounTypeOptions[0]);

  function clearForm() {
    setEmail('');
    setMessage('');
    setType(firmAccounTypeOptions[0]);
  }

  function close() {
    clearForm();
    props.onClose();
  }

  return (
    <AnimatedModal open={props.open} onClose={close}>
      <div>
        <div className="flex items-center justify-between">
          <H3>Invite to {props.firm.name}</H3>
          <CloseIcon onClose={close} />
        </div>

        <form
          className="mt-3"
          onSubmit={(e) => {
            e.preventDefault();
            if (!email) {
              setEmailError('Email is required');
              return;
            }

            createFirmInvite.mutate(
              {
                input: {
                  firmID: props.firm.id,
                  email,
                  message,
                  role: type.value as FirmAccountType,
                },
              },
              {
                onSuccess: () => {
                  queryClient.invalidateQueries({
                    queryKey: ['Account', {}],
                  });
                  queryClient.invalidateQueries({
                    queryKey: ['Firm', { id: props.firm.id }],
                  });
                  toasts.success('Invite sent');
                  close();
                },
                onError: () => {
                  toasts.error('Failed to send invite');
                },
              },
            );
          }}
        >
          <TextInput
            ignore1p
            label="Email"
            placeholder="Enter email..."
            value={email}
            error={emailError}
            onChange={(e) => {
              setEmailError('');
              setEmail(e.currentTarget.value);
            }}
          />

          <label
            htmlFor="firm_account_type_dropdown"
            className="block text-sm mt-3 font-medium leading-6 text-gray-900"
          >
            Account type
          </label>
          <Dropdown
            options={firmAccounTypeOptions}
            selectedOption={type}
            onSelect={(o) => {
              setType(o);
            }}
          />

          <div className="flex items-center justify-between mt-3 ">
            <label
              htmlFor="message"
              className="block text-sm font-medium leading-6 text-gray-900"
            >
              Message
            </label>

            <label className="text-sm leading-6 text-gray-500">Optional</label>
          </div>
          <TextArea
            rows={2}
            name="message"
            label="Message"
            value={message}
            onChange={(e) => {
              setMessage(e.currentTarget.value);
            }}
          />

          <div className="flex justify-end mt-6">
            <Button
              isLoading={createFirmInvite.isPending}
              variant="positive"
              text="Invite"
              loadingText="Inviting..."
              type="submit"
            />
          </div>
        </form>
      </div>
    </AnimatedModal>
  );
}