import { TripleDotMenu } from "@/src/components/TripleDotMenu";
import {
  AccessQuery,
  AccessType,
  DealAccessStatus,
  DealAccountFragment,
  DealGroup,
  DealGroupType,
  GranularAccess,
  useUpdateDealAccountMutation,
  useUpdateDealGroupMutation,
} from "@/src/graphql/generated";
import { classNames } from "@/src/utils/cn";
import { formatEnum } from "@/src/utils/enums";
import { Menu } from "@headlessui/react";
import { formatDistanceToNow, fromUnixTime } from "date-fns";
import {
  CircleCheckIcon,
  CircleMinusIcon,
  PencilIcon,
  UserPlus2Icon,
} from "lucide-react";
import { Fragment, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { InviteMoreToGroup } from "./InviteMoreToGroup";
import { EditDealGroup } from "./EditDealGroup";
import useGqlClient from "@/src/hooks/useGqlClient";
import { useQueryClient } from "@tanstack/react-query";
import { toasts } from "@/src/components/toasts/toasts";
import { EditDealAccount } from "./EditDealAccount";
import { isDealAdmin } from "@/src/utils/isDealAdmin";

interface Group {
  id: string;
  name: string;
  accounts: DealAccountFragment[];
  invites: AccessQuery["deal"]["invites"];
  dealGroup?: AccessQuery["deal"]["guestGroups"][0];
}

export function AccessList(props: {
  dealId: string;
  dataRoomId: string;
  permissions: AccessQuery["deal"]["dataRoom"]["permissions"];
  activeDealAccount: AccessQuery["deal"]["activeDealAccount"];
  groups: AccessQuery["deal"]["guestGroups"];
  otherGuests: AccessQuery["deal"]["otherGuests"];
  invites: AccessQuery["deal"]["invites"];
  accountSearch: string;
}) {
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const updateDealGroup = useUpdateDealGroupMutation(client);
  const updateDealAccount = useUpdateDealAccountMutation(client);
  const [groups, setGroups] = useState<Group[]>([]);
  useEffect(() => {
    const initialGroups: Group[] = props.groups.map((group) => ({
      id: group.id,
      name: group.name,
      accounts: group.accounts,
      dealGroup: group,
      invites: props.invites.filter(
        (invite) => invite.dealGroup && invite.dealGroup.id === group.id
      ),
    }));

    if (
      props.otherGuests.length > 0 ||
      props.invites.filter((invite) => !invite.dealGroup).length > 0
    ) {
      const noGroup: Group = {
        id: "no-group",
        name: "Individual guests",
        accounts: props.otherGuests,
        invites: props.invites.filter((invite) => !invite.dealGroup),
      };

      initialGroups.push(noGroup);
    }

    setGroups(initialGroups);
  }, [props.groups, props.invites, props.otherGuests]);

  const history = useHistory();
  const [openModal, setOpenModal] = useState<
    "" | "invite_more" | "edit_group" | "edit_account"
  >("");

  const [selectedGroup, setSelectedGroup] = useState<DealGroup | null>(null);
  const [selectedAccount, setSelectedAccount] =
    useState<DealAccountFragment | null>(null);
  return (
    <div>
      <div className="px-2 lg:px-0 rounded-md">
        <div className="mt-6 flow-root">
          <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="inline-block ring-1 ring-black/5 min-w-full py-2 align-middle sm:px-6 lg:px-8">
              <div className=" shadow ring-1 ring-black/5 sm:rounded-lg">
                {groups.flatMap((g) => g.accounts).length === 0 ? (
                  <div className="bg-white text-center text-sm text-gray-500 p-4">
                    No guests have joined this deal yet.
                  </div>
                ) : (
                  <table className="min-w-full rounded-lg border-separate border-spacing-0">
                    <thead className=" rounded-lg">
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                        >
                          Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Role
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Access
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Last seen
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-3"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="rounded-b-lg">
                      {groups
                        .filter((g) =>
                          g.id === "no-group" && g.accounts.length === 0
                            ? false
                            : true
                        )
                        .filter((g) =>
                          props.accountSearch.length < 2
                            ? true
                            : g.accounts.some(
                                (a) =>
                                  a.account.email
                                    .toLowerCase()
                                    .includes(
                                      props.accountSearch.toLowerCase()
                                    ) ||
                                  a.account.name
                                    .toLowerCase()
                                    .includes(props.accountSearch.toLowerCase())
                              )
                        )
                        .map((group) => (
                          <Fragment key={group.id}>
                            <tr className="border-y bg-white border-gray-500">
                              <th
                                scope="colgroup"
                                colSpan={5}
                                className="border-y border-gray-300 bg-gray-50 py-2 pl-4  pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                              >
                                <div className="flex items-center gap-x-2">
                                  <p>{group.name}</p>
                                  <p className="text-gray-500 font-normal text-sm">
                                    {group.dealGroup
                                      ? formatDataRoomAccess(
                                          group.dealGroup
                                            .overrideDataRoomAccess,
                                          group.dealGroup.granularAccess,
                                          group.dealGroup.dataRoomPermission,
                                          group.dealGroup,
                                          group.dealGroup.dealAccessStatus
                                        )
                                      : ""}
                                  </p>
                                </div>
                              </th>

                              <th
                                scope="col"
                                className="bg-gray-50 pr-4 sm:pr-3 border-y border-gray-300"
                              >
                                {group.dealGroup &&
                                group.dealGroup.type !== DealGroupType.Owner ? (
                                  <div className="flex justify-end">
                                    <TripleDotMenu width="w-40">
                                      <Menu.Item>
                                        {({ active }) => (
                                          <div
                                            onClick={(e) => {
                                              if (group.dealGroup) {
                                                setSelectedGroup(
                                                  group.dealGroup as DealGroup
                                                );
                                                setOpenModal("invite_more");
                                              }
                                            }}
                                            className={classNames(
                                              active
                                                ? "bg-gray-50 text-gray-700"
                                                : "",
                                              "flex truncate items-center gap-x-2 px-3 py-1 text-sm leading-6 text-gray-600 cursor-pointer"
                                            )}
                                          >
                                            <UserPlus2Icon className="w-4 h-4" />
                                            Invite more
                                          </div>
                                        )}
                                      </Menu.Item>
                                      <Menu.Item>
                                        {({ active }) => (
                                          <div
                                            onClick={(e) => {
                                              if (group.dealGroup) {
                                                setSelectedGroup(
                                                  group.dealGroup as DealGroup
                                                );
                                                setOpenModal("edit_group");
                                              }
                                            }}
                                            className={classNames(
                                              active
                                                ? "bg-gray-50 text-gray-700"
                                                : "",
                                              "flex truncate items-center gap-x-2 px-3 py-1 text-sm leading-6 text-gray-600 cursor-pointer"
                                            )}
                                          >
                                            <PencilIcon className="w-4 h-4" />
                                            Edit group
                                          </div>
                                        )}
                                      </Menu.Item>
                                      <Menu.Item>
                                        {({ active }) => (
                                          <div
                                            onClick={(e) => {
                                              if (group.dealGroup) {
                                                updateDealGroup.mutate(
                                                  {
                                                    input: {
                                                      id: group.dealGroup.id,
                                                      dealAccessStatus:
                                                        group.dealGroup
                                                          .dealAccessStatus ===
                                                        DealAccessStatus.Granted
                                                          ? DealAccessStatus.Blocked
                                                          : DealAccessStatus.Granted,
                                                    },
                                                  },
                                                  {
                                                    onSuccess: () => {
                                                      queryClient.invalidateQueries(
                                                        {
                                                          queryKey: [
                                                            "Access",
                                                            {
                                                              dealId:
                                                                props.dealId,
                                                            },
                                                          ],
                                                        }
                                                      );
                                                      toasts.success(
                                                        group.dealGroup!
                                                          .dealAccessStatus ===
                                                          DealAccessStatus.Granted
                                                          ? "Group blocked"
                                                          : "Group unblocked"
                                                      );
                                                    },
                                                    onError: () => {
                                                      toasts.error(
                                                        group.dealGroup!
                                                          .dealAccessStatus ===
                                                          DealAccessStatus.Granted
                                                          ? "Failed to block group"
                                                          : "Failed to unblock group"
                                                      );
                                                    },
                                                  }
                                                );
                                              }
                                            }}
                                            className={classNames(
                                              active &&
                                                group.dealGroup!
                                                  .dealAccessStatus ===
                                                  DealAccessStatus.Granted
                                                ? "bg-gray-50 text-red-700"
                                                : group.dealGroup!
                                                    .dealAccessStatus ===
                                                  DealAccessStatus.Blocked
                                                ? "bg-gray-50 text-green-700"
                                                : "text-red-600",
                                              "flex truncate items-center gap-x-2 px-3 py-1 text-sm leading-6 text-gray-600 cursor-pointer"
                                            )}
                                          >
                                            {group.dealGroup
                                              ?.dealAccessStatus ===
                                            DealAccessStatus.Granted ? (
                                              <CircleMinusIcon className="w-4 h-4" />
                                            ) : (
                                              <CircleCheckIcon className="w-4 h-4" />
                                            )}
                                            {group.dealGroup!
                                              .dealAccessStatus ===
                                            DealAccessStatus.Granted
                                              ? "Block group"
                                              : "Unblock group"}
                                          </div>
                                        )}
                                      </Menu.Item>
                                    </TripleDotMenu>
                                  </div>
                                ) : null}
                              </th>
                            </tr>
                            {group.accounts.length === 0 ? (
                              <tr className="[&_td]:border-transparent">
                                <td
                                  colSpan={6}
                                  className="bg-white py-2 pl-4 pr-3 text-sm text-gray-500 sm:pl-3"
                                >
                                  No guests have joined this group yet.
                                </td>
                              </tr>
                            ) : null}
                            {group.accounts
                              .filter((a) =>
                                props.accountSearch.length < 2
                                  ? true
                                  : a.account.email
                                      .toLowerCase()
                                      .includes(
                                        props.accountSearch.toLowerCase()
                                      ) ||
                                    a.account.name
                                      .toLowerCase()
                                      .includes(
                                        props.accountSearch.toLowerCase()
                                      )
                              )
                              .map((person, personIdx) => (
                                <tr
                                  key={person.account.email}
                                  className={classNames(
                                    personIdx === 0
                                      ? "border-gray-300"
                                      : "border-gray-200",
                                    "border-t rounded-b-lg bg-white rounded-lg hover:bg-gray-50 cursor-pointer"
                                  )}
                                  onClick={() => {
                                    history.push(
                                      `/deal/access/guest/account/${person.id}/`
                                    );
                                  }}
                                >
                                  <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                                    {person.account.name}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {formatEnum(person.role)}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {person.account.email}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {formatDataRoomAccess(
                                      person.overrideDataRoomAccess,
                                      person.granularAccess,
                                      person.dataRoomPermission,
                                      group.dealGroup,
                                      group.dealGroup
                                        ? group.dealGroup.dealAccessStatus
                                        : person.dealAccessStatus
                                    )}
                                  </td>
                                  <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                    {formatDistanceToNow(
                                      fromUnixTime(person.lastSeen),
                                      { addSuffix: true }
                                    )}
                                  </td>
                                  <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                                    {!isDealAdmin(person.role) && (
                                      <div className="flex justify-end">
                                        <TripleDotMenu width="w-40">
                                          <Menu.Item>
                                            {({ active }) => (
                                              <div
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  if (person) {
                                                    setSelectedAccount(
                                                      person as DealAccountFragment
                                                    );
                                                    setOpenModal(
                                                      "edit_account"
                                                    );
                                                  }
                                                }}
                                                className={classNames(
                                                  active
                                                    ? "bg-gray-50 text-gray-700"
                                                    : "",
                                                  "flex truncate items-center gap-x-2 px-3 py-1 text-sm leading-6 text-gray-600 cursor-pointer"
                                                )}
                                              >
                                                <PencilIcon className="w-4 h-4" />
                                                Edit account
                                              </div>
                                            )}
                                          </Menu.Item>
                                          <Menu.Item>
                                            {({ active }) => (
                                              <div
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  updateDealAccount.mutate(
                                                    {
                                                      input: {
                                                        id: person.id,
                                                        dealAccessStatus:
                                                          person.dealAccessStatus ===
                                                          DealAccessStatus.Granted
                                                            ? DealAccessStatus.Blocked
                                                            : DealAccessStatus.Granted,
                                                        overrideDataRoomAccess:
                                                          person.overrideDataRoomAccess,
                                                        filePermissions:
                                                          person.granularAccess?.files.map(
                                                            (f) => ({
                                                              fileId: f.id,
                                                              type: f.type,
                                                            })
                                                          ),
                                                        folderPermissions:
                                                          person.granularAccess?.folders.map(
                                                            (f) => ({
                                                              folderId: f.id,
                                                              type: f.type,
                                                            })
                                                          ),
                                                        role: person.role,
                                                      },
                                                    },
                                                    {
                                                      onSuccess: () => {
                                                        queryClient.invalidateQueries(
                                                          {
                                                            queryKey: [
                                                              "Access",
                                                              {
                                                                dealId:
                                                                  props.dealId,
                                                              },
                                                            ],
                                                          }
                                                        );
                                                        toasts.success(
                                                          "Account updated"
                                                        );
                                                      },
                                                      onError: () => {
                                                        toasts.error(
                                                          "Failed to update account"
                                                        );
                                                      },
                                                    }
                                                  );
                                                }}
                                                className={classNames(
                                                  active &&
                                                    person.dealAccessStatus ===
                                                      DealAccessStatus.Granted
                                                    ? "bg-gray-50 text-red-700"
                                                    : person.dealAccessStatus ===
                                                      DealAccessStatus.Blocked
                                                    ? "bg-gray-50 text-green-700"
                                                    : "text-red-600",
                                                  "flex truncate items-center gap-x-2 px-3 py-1 text-sm leading-6 text-gray-600 cursor-pointer"
                                                )}
                                              >
                                                {person.dealAccessStatus ===
                                                DealAccessStatus.Granted ? (
                                                  <CircleMinusIcon className="w-4 h-4" />
                                                ) : (
                                                  <CircleCheckIcon className="w-4 h-4" />
                                                )}
                                                {person.dealAccessStatus ===
                                                DealAccessStatus.Granted
                                                  ? "Block account"
                                                  : "Unblock account"}
                                              </div>
                                            )}
                                          </Menu.Item>
                                        </TripleDotMenu>
                                      </div>
                                    )}
                                  </td>
                                </tr>
                              ))}
                          </Fragment>
                        ))}
                    </tbody>
                  </table>
                )}
              </div>
              {props.invites.length > 0 && <InviteTable groups={groups} />}
              <InviteMoreToGroup
                dealId={props.dealId}
                isOpen={openModal === "invite_more" && selectedGroup !== null}
                onClose={() => {
                  setOpenModal("");
                }}
                dealGroup={selectedGroup!}
              />
              <EditDealGroup
                dealGroup={selectedGroup!}
                permissions={props.permissions}
                open={openModal === "edit_group" && selectedGroup !== null}
                onClose={() => {
                  setOpenModal("");
                }}
                dealId={props.dealId}
              />
              <EditDealAccount
                dealAccount={selectedAccount}
                permissions={props.permissions}
                open={openModal === "edit_account" && selectedAccount !== null}
                onClose={() => {
                  setOpenModal("");
                }}
                dealId={props.dealId}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function InviteTable(props: { groups: Group[] }) {
  const groups = props.groups.filter((g) => g.invites.length > 0);

  return (
    <div className="mt-8">
      <p className="text-sm text-gray-700 font-semibold mt-4">Invites</p>

      <div className="mt-2 overflow-hidden shadow ring-1 ring-black/5 sm:rounded-lg">
        <table className="min-w-full rounded-lg">
          <thead className="bg-white">
            <tr>
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
              >
                Email
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Role
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Access
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Status
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
              >
                Invite sent
              </th>

              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-3">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          <tbody className="bg-white">
            {groups
              .filter((g) => g.invites.length > 0)
              .map((group) => (
                <Fragment key={group.id}>
                  <tr className="border-t border-gray-200">
                    <th
                      scope="colgroup"
                      colSpan={5}
                      className="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                    >
                      {group.name}
                    </th>
                  </tr>
                  {group.invites.map((invite, inviteIdx) => (
                    <tr
                      key={invite.email}
                      className={classNames(
                        inviteIdx === 0 ? "border-gray-300" : "border-gray-200",
                        "border-t"
                      )}
                    >
                      <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-3">
                        {invite.email}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {formatEnum(invite.role)}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {formatDataRoomAccess(
                          invite.overrideDataRoomAccess,
                          invite.granularAccess as GranularAccess,
                          invite.dataRoomPermission,
                          invite.dealGroup as DealGroup
                        )}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {formatEnum(invite.status)}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {formatDistanceToNow(fromUnixTime(invite.createdAt), {
                          addSuffix: true,
                        })}
                      </td>

                      <td className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-3">
                        <button className="text-indigo-600 hover:text-indigo-900">
                          Edit
                          <span className="sr-only">, {invite.email}</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </Fragment>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export function formatDataRoomAccess(
  overrideDataRoomAccess: boolean,
  granularAccess?:
    | AccessQuery["deal"]["otherGuests"][0]["granularAccess"]
    | null,
  permission?: AccessQuery["deal"]["invites"][0]["dataRoomPermission"] | null,
  group?: AccessQuery["deal"]["guestGroups"][0] | null,
  dealAccessStatus?: DealAccessStatus
) {
  if (dealAccessStatus && dealAccessStatus === DealAccessStatus.Blocked) {
    return "Blocked";
  }

  if (overrideDataRoomAccess && granularAccess) {
    return `Restricted to ${
      granularAccess.files.filter((f) => f.type !== AccessType.NoAccess).length
    } ${
      granularAccess.files.filter((f) => f.type !== AccessType.NoAccess)
        .length === 1
        ? "file"
        : "files"
    }`;
  }

  if (group && group.dataRoomPermission) {
    return `${group.dataRoomPermission.name} (${group.dataRoomPermission.fileCount} files)`;
  }

  return permission
    ? `${permission.name} (${permission.fileCount} files)`
    : "No access";
}
