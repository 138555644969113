import { ArrowUpIcon, BotIcon, PlusIcon } from "lucide-react";
import { classNames } from "@/src/utils/cn";
import {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useOutsideClick } from "@/src/hooks/useOutsideClick";
import { CloseIcon } from "@/src/components/CloseIcon";
import { deepSearchSelectors } from "@/src/store/deep-search/selector";
import { useDispatch, useSelector } from "react-redux";
import { AppState } from "@/src/store";
import useGqlClient from "@/src/hooks/useGqlClient";
import {
  DataRoomFileQuery,
  DealThreadMessageRole,
  DealThreadMessageStatus,
  DeepSearchQuery,
  useCreateDeepSearchFileMutation,
  useCreateDeepSearchThreadMessageMutation,
  useDataRoomFileQuery,
  useDeepSearchQuery,
  useDeepSearchThreadMessagesQuery,
} from "@/src/graphql/generated";
import { v4 as uuidv4 } from "uuid";
import { actions } from "@/src/store/deep-search/slice";
import { useQueryClient } from "@tanstack/react-query";
import { useTransition } from "react-spring";
import { AssistantMessage } from "./AssistantMessage";
import { FileIcon } from "@/src/components/FileIcon";
import { StatusDot } from "@/src/components/StatusDot";

interface ScrollContextType {
  triggerScroll: () => void;
}

export const ScrollContext = createContext<ScrollContextType>({
  triggerScroll: () => {},
});

export function Assistant(props: { deepSearchId: string }) {
  const [isChatExpanded, setIsChatExpanded] = useState(false);
  const chatRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  useOutsideClick(chatRef, (event) => {
    const isClickedInModal =
      event.target instanceof Element &&
      event.target.closest('[role="dialog"]');

    if (!isClickedInModal) {
      setIsChatExpanded(false);
    }
  });

  const [message, setMessage] = useState("");

  const scrollToBottom = useCallback(() => {
    if (isChatExpanded) {
      scrollRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [isChatExpanded]);

  const deepSearchThreadMessages = useSelector((state: AppState) =>
    deepSearchSelectors.deepSearchThreadMessages(state, props.deepSearchId)
  );

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const client = useGqlClient();
  const deepSearchThreadMessagesQuery = useDeepSearchThreadMessagesQuery(
    client,
    {
      deepSearchId: props.deepSearchId,
    }
  );

  useEffect(() => {
    if (deepSearchThreadMessagesQuery.data) {
      dispatch(
        actions.setDeepThreadMessages({
          deepSearchId: props.deepSearchId,
          messages:
            deepSearchThreadMessagesQuery.data.deepSearch.threadMessages.map(
              (m) => {
                return {
                  deepSearchId: props.deepSearchId,
                  deepSearchThreadMessageID: m.id,
                  deepSearchThreadMessageContent: {
                    role: m.role,
                    status: m.status,
                    type: "text",
                    text: {
                      value: m.content.text,
                    },
                  },
                  deepSearchThreadMessageFileIds: m.files.map((f) => f.id),
                };
              }
            ),
        })
      );
    }
  }, [deepSearchThreadMessagesQuery.data, dispatch, props.deepSearchId]);

  const createDeepSearchThreadMessage =
    useCreateDeepSearchThreadMessageMutation(client);

  useEffect(() => {
    scrollToBottom();
  }, [isChatExpanded, deepSearchThreadMessages, scrollToBottom]);

  const transitions = useTransition(deepSearchThreadMessages, {
    from: { transform: "translate3d(0,40px,0)", opacity: 0 },
    enter: { transform: "translate3d(0,0px,0)", opacity: 1 },
    leave: { transform: "translate3d(0,-40px,0)", opacity: 0 },
    keys: (message) => (message as any).deepSearchThreadMessageID,
  });

  const deepSearchThreadMessageIndexMap = useMemo(() => {
    return deepSearchThreadMessages.reduce((acc, message, index) => {
      if (!message.deepSearchThreadMessageID) {
        return acc;
      }
      acc[message.deepSearchThreadMessageID] = index;
      return acc;
    }, {} as { [key: string]: number });
  }, [deepSearchThreadMessages]);

  const deepSearchThreadFileIds = useMemo(() => {
    return Array.from(
      new Set(
        deepSearchThreadMessages.reduce((acc, message) => {
          return acc.concat(message.deepSearchThreadMessageFileIds);
        }, [] as string[])
      )
    );
  }, [deepSearchThreadMessages]);

  return (
    <ScrollContext.Provider value={{ triggerScroll: scrollToBottom }}>
      <div
        ref={chatRef}
        className={classNames(
          "absolute transition-all duration-300 ease-in-out z-20",
          isChatExpanded
            ? "bottom-6 left-1/2 -translate-x-1/2 w-[90%] max-w-4xl h-[600px] bg-white rounded-lg shadow-2xl border border-gray-400"
            : "bottom-6 left-1/2 -translate-x-1/2 w-[90%] overflow-hidden max-w-md h-12 opacity-80 hover:opacity-100"
        )}
      >
        <div
          className={classNames(
            "transition-opacity duration-200 ",
            isChatExpanded
              ? "flex flex-col opacity-100 h-[calc(100%-60px)] "
              : "opacity-0 h-0"
          )}
        >
          <div className="w-full flex items-center justify-between bg-concrete-50 border-b border-concrete-200 p-3 rounded-t-lg">
            <div className="flex items-center gap-2">
              <BotIcon className="h-5 w-5 text-gray-700" />
              <div>
                <p className="font-semibold text-md text-gray-700">
                  Referencing Assistant
                </p>
                <p className="text-sm text-gray-500">
                  Use this assistant to help you find relevant documents e.g.
                  "I'm looking for documents referring to Closing Clause 9.1"
                </p>
              </div>
            </div>
            <CloseIcon onClose={() => setIsChatExpanded(false)} />
          </div>
          <div className="flex flex-1 overflow-hidden">
            <div className="space-y-4 p-4 flex-1 overflow-y-scroll">
              {transitions((style, message) => {
                if (!message.deepSearchThreadMessageContent) {
                  return null;
                }

                return (
                  <AssistantMessage
                    style={style}
                    deepSearchId={props.deepSearchId}
                    deepThreadMessageId={message.deepSearchThreadMessageID}
                    index={
                      deepSearchThreadMessageIndexMap[
                        message.deepSearchThreadMessageID
                      ]
                    }
                    isLast={
                      deepSearchThreadMessageIndexMap[
                        message.deepSearchThreadMessageID
                      ] ===
                      deepSearchThreadMessages.length - 1
                    }
                  />
                );
              })}
              <div ref={scrollRef} />
            </div>
            {/* {deepSearchThreadFileIds.length > 0 ? (
              <Sidebar
                deepSearchThreadFileIds={deepSearchThreadFileIds}
                onDocumentAdded={() => {}}
                deepSearchId={props.deepSearchId}
              />
            ) : null} */}
          </div>
        </div>

        <form
          onSubmit={(e) => {
            e.preventDefault();
            const newId = uuidv4();
            dispatch(
              actions.addDeepThreadMessage({
                deepSearchId: props.deepSearchId,
                message: {
                  deepSearchId: props.deepSearchId,
                  deepSearchThreadMessageID: newId,
                  deepSearchThreadMessageFileIds: [],
                  deepSearchThreadMessageContent: {
                    type: "text",
                    text: { value: message },
                    role: DealThreadMessageRole.User,
                    status: DealThreadMessageStatus.Sending,
                  },
                },
              })
            );
            setMessage("");
            createDeepSearchThreadMessage.mutate(
              {
                input: {
                  deepSearchID: props.deepSearchId,
                  message: message,
                  id: newId,
                },
              },
              {
                onError: () => {
                  dispatch(
                    actions.updateDealThreadMessage({
                      deepSearchId: props.deepSearchId,
                      messageId: newId,
                      message: {
                        deepSearchId: props.deepSearchId,
                        deepSearchThreadMessageID: newId,
                        deepSearchThreadMessageFileIds: [],
                        deepSearchThreadMessageContent: {
                          type: "text",
                          text: { value: message },
                          role: DealThreadMessageRole.User,
                          status: DealThreadMessageStatus.Failed,
                        },
                      },
                    })
                  );
                },
                onSuccess: (res) => {
                  deepSearchThreadMessagesQuery.refetch();

                  queryClient.invalidateQueries({
                    queryKey: ["DeepSearch", props.deepSearchId],
                  });
                  dispatch(
                    actions.updateDealThreadMessage({
                      deepSearchId: props.deepSearchId,
                      messageId: newId,
                      message: {
                        deepSearchId: props.deepSearchId,
                        deepSearchThreadMessageID: newId,
                        deepSearchThreadMessageFileIds: [],
                        deepSearchThreadMessageContent: {
                          type: "text",
                          text: { value: message },
                          role: DealThreadMessageRole.User,
                          status: DealThreadMessageStatus.Sent,
                        },
                      },
                    })
                  );
                },
              }
            );
          }}
          className={classNames(
            "absolute bottom-0 left-0 right-0 p-3 flex items-center gap-x-2",
            isChatExpanded
              ? "border-t border-concrete-200 bg-concrete-50 rounded-b-lg"
              : ""
          )}
        >
          <input
            type="text"
            className={classNames(
              "flex-1 border-0 text-gray-900 placeholder:text-gray-400 focus:ring-inset focus:ring-gray-600 sm:text-sm sm:leading-6 bg-white",
              isChatExpanded
                ? "rounded-full py-1.5 px-4 ring-1 ring-inset ring-gray-300"
                : "rounded-full py-1.5 px-4 shadow-lg ring-1 ring-inset ring-gray-300"
            )}
            placeholder="Ask the assistant to search for more documents..."
            value={message}
            onChange={(e) => setMessage(e.currentTarget.value)}
            onFocus={() => setIsChatExpanded(true)}
            // Optional: Close on Escape key
            onKeyDown={(e) => {
              if (e.key === "Escape") {
                setIsChatExpanded(false);
                e.currentTarget.blur();
              }
            }}
          />
          <button
            className={classNames(
              "p-1.5 bg-persian-600 hover:bg-persian-700 rounded-full shadow-sm",
              isChatExpanded
                ? message.length > 0
                  ? "opacity-100"
                  : "opacity-50 cursor-not-allowed"
                : "opacity-0"
            )}
          >
            <ArrowUpIcon className="h-4 w-4 text-white" />
          </button>
        </form>
      </div>
    </ScrollContext.Provider>
  );
}

function Sidebar(props: {
  deepSearchThreadFileIds: string[];
  onDocumentAdded: (fileId: string, versionId: string) => void;
  deepSearchId: string;
}) {
  const client = useGqlClient();
  const deepSearchQuery = useDeepSearchQuery(client, {
    id: props.deepSearchId,
  });

  if (!deepSearchQuery.data) {
    return null;
  }

  return (
    <div className="w-64 border-l bg-concrete-50 border-concrete-200 p-3 space-y-2">
      <p className="text-xs text-gray-500 font-medium">Mentioned documents</p>
      {props.deepSearchThreadFileIds.map((fileId) => {
        return (
          <SidebarDocument
            fileId={fileId}
            onDocumentAdded={props.onDocumentAdded}
            deepSearch={deepSearchQuery.data.deepSearch}
          />
        );
      })}
    </div>
  );
}

function SidebarDocument(props: {
  fileId: string;
  onDocumentAdded: (fileId: string, versionId: string) => void;
  deepSearch: DeepSearchQuery["deepSearch"];
}) {
  const client = useGqlClient();
  const dataRoomFileQuery = useDataRoomFileQuery(client, {
    id: props.fileId,
  });

  if (!dataRoomFileQuery.data) {
    return null;
  }

  return (
    <div className="p-2 bg-white rounded-lg shadow-sm border border-gray-200">
      <div className="flex flex-row items-center gap-x-2">
        <FileIcon
          fileType={dataRoomFileQuery.data.dataRoomFile.fileType}
          size="s"
        />
        <p className="text-sm text-gray-700 font-semibold line-clamp-1 hover:line-clamp-none">
          {dataRoomFileQuery.data.dataRoomFile.name}
        </p>
      </div>
      <p className="text-xs text-gray-500 mt-2">Versions</p>
      <div className="space-y-2">
        {dataRoomFileQuery.data.dataRoomFile.versions.map((version) => {
          return (
            <SidebarDocumentVersion
              key={version.id}
              dataRoomFile={dataRoomFileQuery.data.dataRoomFile}
              deepSearch={props.deepSearch}
              fileId={props.fileId}
              version={version}
            />
          );
        })}
      </div>
    </div>
  );
}

function SidebarDocumentVersion(props: {
  dataRoomFile: DataRoomFileQuery["dataRoomFile"];
  deepSearch: DeepSearchQuery["deepSearch"];
  fileId: string;
  version: DataRoomFileQuery["dataRoomFile"]["versions"][0];
}) {
  const client = useGqlClient();
  const queryClient = useQueryClient();
  const createDeepSearchFile = useCreateDeepSearchFileMutation(client);

  return (
    <div className="flex flex-row items-center gap-x-2 justify-between">
      <div className="flex flex-row items-center gap-x-2">
        <StatusDot
          status={
            props.version.id === props.dataRoomFile.currentLiveVersion.id
              ? "positive"
              : "neutral"
          }
        />
        <p className="text-xs text-gray-600 font-semibold">
          Version {props.version.versionNumber}
        </p>
      </div>

      {props.deepSearch.files.filter(
        (f) =>
          f.file.id === props.fileId && f.currentVersion.id === props.version.id
      ).length > 0 ? (
        <div className="flex flex-row items-center gap-x-2">
          <p className="text-xs text-gray-600 font-semibold">Document added</p>
        </div>
      ) : (
        <div className="flex flex-row items-center gap-x-2">
          <button
            onClick={() => {
              if (createDeepSearchFile.isPending) {
                return;
              }

              createDeepSearchFile.mutate(
                {
                  input: {
                    deepSearchID: props.deepSearch.id,
                    dataRoomFileID: props.fileId,
                    dataRoomFileVersionID: props.version.id,
                    references: [],
                  },
                },
                {
                  onSuccess: () => {
                    queryClient.invalidateQueries({
                      queryKey: ["DeepSearch", { id: props.deepSearch.id }],
                    });
                  },
                }
              );
            }}
            className="flex items-center gap-x-1 text-xs text-persian-600 hover:text-persian-900 font-semibold"
          >
            <PlusIcon className="h-3 w-3" />
            {createDeepSearchFile.isPending ? "Adding..." : "Add document"}
          </button>
        </div>
      )}
    </div>
  );
}
