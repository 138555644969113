import { DeepSearchThreadMessageUpdatedData } from "@/src/contexts/websockets";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export type DeepSearchState = {
  showSidebar: {
    [deepSearchId: string]: boolean;
  };
  deepSearchThreads: {
    [deepSearchId: string]: DeepSearchThread;
  };
};

interface DeepSearchThread {
  id: string;
  messages: DeepSearchThreadMessageUpdatedData[];
}

const initialState: DeepSearchState = {
  showSidebar: {},
  deepSearchThreads: {},
};

export const { actions, reducer } = createSlice({
  name: "dataRoomFile",
  initialState,
  reducers: {
    setShowSidebar: (
      state,
      action: PayloadAction<{ deepSearchId: string; show: boolean }>
    ) => {
      state.showSidebar[action.payload.deepSearchId] = action.payload.show;
    },
    setDeepThreadMessages: (
      state,
      action: PayloadAction<{
        deepSearchId: string;
        messages: DeepSearchThreadMessageUpdatedData[];
      }>
    ) => {
      if (!state.deepSearchThreads[action.payload.deepSearchId]) {
        state.deepSearchThreads[action.payload.deepSearchId] = {
          id: action.payload.deepSearchId,
          messages: [],
        };
      }
      state.deepSearchThreads[action.payload.deepSearchId].messages =
        action.payload.messages;
    },
    updateDealThreadMessage: (
      state,
      action: PayloadAction<{
        deepSearchId: string;
        messageId: string;
        message: DeepSearchThreadMessageUpdatedData;
      }>
    ) => {
      state.deepSearchThreads[action.payload.deepSearchId].messages =
        state.deepSearchThreads[action.payload.deepSearchId].messages.map(
          (message) => {
            if (
              message.deepSearchThreadMessageID === action.payload.messageId
            ) {
              return action.payload.message;
            }
            return message;
          }
        );
    },
    addDeepThreadMessage: (
      state,
      action: PayloadAction<{
        deepSearchId: string;
        message: DeepSearchThreadMessageUpdatedData;
      }>
    ) => {
      if (!state.deepSearchThreads[action.payload.deepSearchId]) {
        state.deepSearchThreads[action.payload.deepSearchId] = {
          id: action.payload.deepSearchId,
          messages: [],
        };
      }
      state.deepSearchThreads[action.payload.deepSearchId].messages.push(
        action.payload.message
      );
    },
  },
});
